<template>
  <div v-if="!$store.state.app.isLoading" class="locale-switcher" :class="getClass()">
    <img
      alt="loc"
      width="24px"
      height="24px"
      class="locale-flag"
      :src="require(`@/assets/images/${$i18n.locale}.webp`)"
    />

    <v-select
      @change="setLocale($event)"
      attach
      :class="$i18n.locale + '-flag locale-item-flag'"
      item-value="loc"
      item-text="text"
      :items="locales"
    >
      <template v-slot:item="data">
        <v-list-item v-bind="data.attrs" v-on="data.on">
          <img
            width="24px"
            height="24px"
            :src="require(`@/assets/images/${data.item.loc}.webp`)"
            alt="loc"
          />
          <v-list-item-content>
            <v-list-item-title v-html="data.item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: "LocaleSwitcher",
  computed: {
    locales() {
      return [
        {
          loc: "en-us",
          text: "English",
        },
        {
          loc: "zh-hk",
          text: "繁體中文",
        },
        {
          loc: "es-us",
          text: "Español",
        },
        {
          loc: "ja-jp",
          text: "日本",
        },
      ];
    },
  },
  created() {
    let newLang = "";
    let oldlang = localStorage.getItem("language");
    if (oldlang && oldlang.includes("_")) {
      newLang = oldlang.replace("_", "-");
      this.$i18n.locale = newLang;
      this.$store.commit("app/set_lang", newLang);
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$store.commit("app/set_lang", locale);
    },
    getClass() {
      switch (this.$route.path) {
        case "/promo":
          return " promo";
        case "/login":
          return " login";
        default:
          return "platform";
      }
    },
  },
};
</script>
