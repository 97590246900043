<template>
  <div class="guest-tree" v-show="show">
    <div v-show="!reading && step === 5">
      <h3 class="main-title" style="font-size: 20px !important">
        {{ $t("form_check") }}
      </h3>
      <h3 class="modal-label reg-font">
        {{ $t("form_check_info") }}
      </h3>
    </div>
    <div v-show="!reading">
      <div class="d-flex justify-space-between align-center" style="height: 80px">
        <h3>
          <span class="main-title d-block" style="font-size: 20px !important">
            {{ $t("form_tree") }}
          </span>
          <span class="modal-label reg-font">
            {{ $t("form_see_tree") }}
          </span>
        </h3>
        <template>
          <v-select
            attach
            v-if="hasChosen && this.shownCheckbox"
            v-model="current_action"
            @input="chooseAction($event)"
            :style="{ maxWidth: '200px' }"
            class="modal-input"
            :items="actions"
            :label="$t('actions')"
          />
          <v-btn v-else text class="modal-btn" @click="shownCheckbox = !shownCheckbox">{{
            $t("click_to_modify")
          }}</v-btn>
        </template>
      </div>
    </div>

    <div v-if="!reading" class="row tab-0">
      <!-- <div class="d-flex align-center"> -->
      <v-checkbox
        v-if="shownCheckbox"
        :value="chosenAll"
        @change="chooseAll($event, false)"
        class="filter-checkbox ma-0 mr-3 mb-1"
        :class="chosenAll ? 'active' : ''"
      />
      <h3 class="modal-label first d-flex align-center justify-start">
        <span class="mr-3">{{ group.group_name || "N/A" }}</span>
        <Calendar :calendarClass="getDateWarning('group', group)" />
      </h3>
      <v-btn
        v-show="!isReadonly && !shownCheckbox"
        @click="
          reset();
          chooseAll(true, true);
          edit(0);
        "
        text
        class="modal-btn ml-auto"
        >{{ $t("change") }}</v-btn
      >
    </div>
    <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
      <div
        class="row tab-1"
        v-if="
          group.group_name !== 'Over-parking guests' &&
          !(isOnetime || isDrivers || mode === 1) &&
          (subgroup.show || !hasSearch)
        "
      >
        <div class="d-flex align-center">
          <v-checkbox
            v-if="shownCheckbox"
            :value="chosen.subgroups.includes(subgroup)"
            @change="choose($event, subgroup, 'subgroups', sIndex, null, false)"
            class="filter-checkbox ma-0 mr-3 mb-1"
            :class="chosen.subgroups.includes(subgroup) ? 'active' : ''"
          />
          <h3 class="modal-label first">
            {{ subgroup.group_name || "N/A" }}
          </h3>
        </div>
        <v-btn
          v-show="!isReadonly && !shownCheckbox"
          @click="
            reset();
            choose(true, subgroup, 'subgroups', sIndex, null, true);
            edit(1);
          "
          text
          class="modal-btn ml-auto"
          >{{ $t("change") }}</v-btn
        >
      </div>

      <div
        v-for="(person, personIndex) in [...subgroup.guests].sort(
          (a, b) =>
            new Date(a.end_date).getTime() - new Date(b.end_date).getTime() ||
            a.first_name.localeCompare(b.first_name)
        )"
        :key="personIndex"
        class="tab-2-container"
      >
        <div
          class="row tab-2"
          v-if="mode !== 1 && !isOnetime && (person.show || !hasSearch)"
        >
          <!-- <div class="d-flex align-center"> -->
          <v-checkbox
            v-if="shownCheckbox"
            :value="chosen.guests.includes(person)"
            @change="
              choose(
                $event,
                person,
                'guests',
                sIndex,
                subgroup.guests.indexOf(person),
                false
              )
            "
            class="filter-checkbox ma-0 mr-3 mb-1"
            :class="chosen.guests.includes(person) ? 'active' : ''"
          />
          <h3 class="modal-label first">
            <span> {{ person.first_name }} {{ person.last_name }}</span>
            <Calendar :calendarClass="getDateWarning('guest', person)" />
          </h3>
          <!-- </div> -->
          <h3 class="modal-label reg-font tree-col2">
            {{
              $tc("spaces_qty", person.spaces.getSumByKey("space_count"), {
                n: person.spaces.getSumByKey("space_count"),
              })
            }}
          </h3>
          <h3 class="modal-label reg-font tree-date">
            <div
              v-for="record in person.spaces"
              :key="record.record_id"
              class="full-width"
            >
              {{
                record.start_date
                  ? `${$moment(record.start_date).format("D.MM.YYYY")}`
                  : ""
              }}
              {{
                record.end_date ? `- ${$moment(record.end_date).format("D.MM.YYYY")}` : ""
              }}
            </div>
          </h3>
          <p class="modal-label reg-font mb-1 tree-last-col">
            {{ person.guest_type === 3 ? $t("non_pbnb") : "" }}
          </p>
          <v-btn
            v-show="!isReadonly && !shownCheckbox"
            @click="
              reset();
              choose(
                true,
                person,
                'guests',
                sIndex,
                subgroup.guests.indexOf(person),
                true
              );
              edit(2);
            "
            text
            class="modal-btn"
            >{{ $t("change") }}</v-btn
          >
        </div>

        <div
          v-for="(car, carIndex) in [...person.cars]
            .filter((i) => i.status !== 3)
            .sort(
              (a, b) =>
                new Date(a.end_date).getTime() - new Date(b.end_date).getTime() ||
                a.car_plate.localeCompare(b.car_plate)
            )"
          :key="carIndex"
        >
          <div class="row tab-3" v-if="car.show || !hasSearch">
            <!-- <div class="d-flex align-center"> -->
            <v-checkbox
              v-if="shownCheckbox"
              :value="chosen.cars.includes(car)"
              @change="
                choose(
                  $event,
                  car,
                  'cars',
                  sIndex,
                  subgroup.guests.indexOf(person),
                  false
                )
              "
              class="filter-checkbox ma-0 mr-3 mb-1"
              :class="chosen.cars.includes(car) ? 'active' : ''"
            />
            <h3 class="modal-label first d-flex align-center">
              <span style="width: 100px">{{ car.car_plate }}</span>
              <span class="reg-font mr-3" v-if="$store.getters['app/hasZones']">
                {{ $t("zone") }}
                {{
                  zones.slice().filter((z) => z.zone_id === car.zone_id)[0]
                    ? zones.slice().filter((z) => z.zone_id === car.zone_id)[0].zone_name
                    : ""
                }}
              </span>
              <Calendar :calendarClass="getDateWarning('car', car)" />
            </h3>
            <!-- </div> -->
            <h3 class="modal-label reg-font tree-col2">
              {{ $t(`car_types[${car.car_type}]`) }}
            </h3>
            <h3 class="modal-label reg-font tree-date" v-if="!isOnetime">
              {{ car.start_date ? $moment(car.start_date).format("D.MM.YYYY") : "" }}
              {{ car.end_date ? `- ${$moment(car.end_date).format("D.MM.YYYY")}` : "" }}
            </h3>
            <h3 class="modal-label reg-font tree-date" v-else>
              {{ car.end_date ? `${$moment(car.end_date).format("D.MM.YYYY")}` : "" }}
            </h3>
            <h3 class="modal-label reg-font tree-last-col">
              {{ car.brand_name }}
            </h3>
            <v-btn
              v-show="!isReadonly && !shownCheckbox"
              @click="
                reset();
                choose(true, car, 'cars', sIndex, subgroup.guests.indexOf(person), true);
                edit(3);
              "
              text
              class="modal-btn mb-2"
              >{{ $t("change") }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="!reading" class="d-flex align-center justify-end">
      <v-btn @click="back" text class="flat-btn modal-footer-btn mr-6">{{
        $t("back")
      }}</v-btn>
      <v-btn :loading="sending" @click="send" text class="main-btn my-6 px-4 py-2">{{
        $t("done")
      }}</v-btn>
    </div>
    <Datepicker ref="datePicker" @save="saveDates" :solo="true" />
  </div>
</template>

<script>
import { base } from "@/axios-api";
export default {
  components: {
    Datepicker: () => import("../core/Datepicker.vue"),
    Calendar: () => import("../UI/Calendar.vue"),
  },
  name: "GuestTree",
  props: {
    group: {},
    show: {
      type: Boolean,
      default: true,
    },
    reading: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current_action: undefined,
      chosen: {
        subgroups: [],
        guests: [],
        cars: [],
      },
      shownCheckbox: false,
      chosenAll: false,
      busyDelete: false,
    };
  },
  computed: {
    place() {
      return this.$store.state.places[0];
    },
    zones() {
      return this.$store.state.app.zones;
    },
    actions() {
      return [
        this.$t("change_end_date"),
        this.$t("edit_selected"),
        this.$t("non_pbnb"),
        this.$t("delete"),
      ];
    },
    carTypes() {
      return this.$store.state.app.car_types;
    },
    hasChosen() {
      return (
        this.chosenAll ||
        this.chosen.subgroups.length ||
        this.chosen.guests.length ||
        this.chosen.cars.length
      );
    },
    isDrivers() {
      return (
        (this.group.group_name.includes("Drivers") ||
          this.group.group_name.includes("Customers")) &&
        !this.isOnetime
      );
    },
    isOnetime() {
      return this.group.group_name.includes("One-time parking");
    },
    bvSpecial() {
      let name = this.group.group_name;
      return (
        (name.includes("Discounted") || name.includes("VIP") || name === "Monthly") &&
        this.place.place_id === 3146
      );
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    mode() {
      return Number(this.group.is_simple);
    },
    deletable() {
      return !this.isDrivers && !this.isOnetime && !this.bvSpecial;
    },
  },
  watch: {
    show() {
      if (!this.hasChosen) {
        this.reset();
      }
    },
  },
  methods: {
    getDateWarning(depth, item) {
      let now = this.$moment();
      let weekLeft = false;
      let threeDaysLeft = false;
      let pastDate = false;

      let g, c, spaceDate, carDate, spaceDiff, compCarDiff, guestCarDiff, carDiff;
      switch (depth) {
        case "group":
          item.subgroups.map((d) => {
            d.guests.map((g) => {
              g.spaces.map((c) => {
                spaceDate = this.$moment(c.end_date);
                spaceDiff = spaceDate.diff(now, "days");
                if (now.isAfter(spaceDate)) {
                  pastDate = true;
                } else if (spaceDiff <= 3) {
                  threeDaysLeft = true;
                } else if (spaceDiff <= 7) {
                  weekLeft = true;
                }
              });
              g.cars.map((c) => {
                carDate = this.$moment(c.end_date);
                compCarDiff = carDate.diff(now, "days");
                if (now.isAfter(carDate)) {
                  pastDate = true;
                } else if (compCarDiff <= 3) {
                  threeDaysLeft = true;
                } else if (compCarDiff <= 7) {
                  weekLeft = true;
                }
              });
            });
          });
          break;
        case "guest":
          g = { ...item };
          g.spaces.map((c) => {
            spaceDate = this.$moment(c.end_date);
            spaceDiff = spaceDate.diff(now, "days");
            if (now.isAfter(spaceDate)) {
              pastDate = true;
            } else if (spaceDiff <= 3) {
              threeDaysLeft = true;
            } else if (spaceDiff <= 7) {
              weekLeft = true;
            }
          });

          g.cars.map((c) => {
            carDate = this.$moment(c.end_date);
            guestCarDiff = carDate.diff(now, "days");
            if (now.isAfter(carDate)) {
              pastDate = true;
            } else if (guestCarDiff <= 3) {
              threeDaysLeft = true;
            } else if (guestCarDiff <= 7) {
              weekLeft = true;
            }
          });
          break;

        case "car":
          c = { ...item };
          carDate = this.$moment(c.end_date);
          carDiff = carDate.diff(now, "days");
          if (now.isAfter(carDate)) {
            pastDate = true;
          } else if (carDiff <= 3) {
            threeDaysLeft = true;
          } else if (carDiff <= 7) {
            weekLeft = true;
          }
          break;
        default:
          break;
      }
      if (pastDate) {
        return "red-svg";
      } else if (threeDaysLeft) {
        return "orange-svg";
      } else if (weekLeft) {
        return "yellow-svg";
      } else {
        return "d-none";
      }
    },
    edit(tab) {
      this.changeContentHandler();
      this.$store.commit("guests/set_guest_tab", tab);
      this.$emit("clickedEdit", tab);
    },
    back() {
      this.$store.commit("guests/set_guest_tab", 3);
    },
    redirectToResult() {
      this.$store.commit("guests/set_guest_tab", 5);
    },
    reset() {
      this.current_action = undefined;
      this.shownCheckbox = false;

      for (let key of Object.keys(this.chosen)) {
        this.chosen[key].map((choice) => {
          choice.chosen = false;
        });
      }
      this.chosen = {
        subgroups: [],
        guests: [],
        cars: [],
      };

      this.busyDelete = false;
    },
    send() {
      this.$emit("clickedSend");
      this.reset();
    },
    chooseAll(check, nest) {
      this.group.subgroups.map((d) => {
        this.choose(check, d, "subgroups", null, nest);
      });
      this.chosenAll = check;
    },
    choose(check, item, depth, subgroup, guest, nest) {
      this.chosen[depth].push(item);
      if (check) {
        switch (depth) {
          case "subgroups":
            if (nest) {
              this.chooseAll();
            }
            item.guests.map((g) => {
              this.chosen.guests.push(g);
              g.cars.map((c) => {
                this.chosen.cars.push(c);
              });
            });
            break;
          case "guests":
            if (nest) {
              this.chosen.subgroups.push(this.group.subgroups[subgroup]);
            }
            item.cars.map((c) => {
              this.chosen.cars.push(c);
            });
            break;
          case "cars":
            if (nest) {
              this.chosen.subgroups.push(this.group.subgroups[subgroup]);
              this.chosen.guests.push(this.group.subgroups[subgroup].guests[guest]);
            }
            if (this.isOnetime || this.mode === 1) {
              this.chosen.guests.push(this.group.subgroups[subgroup].guests[guest]);
            }
            break;
          default:
            break;
        }
      } else {
        let index = this.chosen[depth].indexOf(item);
        this.chosen[depth].splice(index, 1);

        if (item.subgroups) {
          this.chosen = {
            subgroups: [],
            guests: [],
            cars: [],
          };
          this.chosenAll = false;
        } else if (item.guests) {
          let guestIDs = [];
          let carIDs = [];
          item.guests.map((g) => {
            guestIDs.push(g.guest_id);
            g.cars.map((c) => {
              carIDs.push(c.car_id);
            });
          });
          this.chosen.guests = this.chosen.guests.filter(
            (i) => guestIDs.includes(i.guest_id) === false
          );
          this.chosen.cars = this.chosen.cars.filter(
            (i) => carIDs.includes(i.car_id) === false
          );
        } else if (item.cars) {
          let carIDs = [];
          item.cars.map((c) => {
            this.chosen.cars.push(c);
            carIDs.push(c.car_id);
          });
          this.chosen.cars = this.chosen.cars.filter(
            (i) => carIDs.includes(i.car_id) === false
          );
          this.chosen.guests = this.chosen.guests.filter(
            (i) => i.guest_id !== item.guest_id
          );
        }
      }
    },
    openDatepicker() {
      this.$refs.datePicker.show();
    },
    chooseAction(event) {
      let action = this.actions.indexOf(event);

      switch (action) {
        case 0:
          this.openDatepicker();
          break;
        case 1:
          this.changeContentHandler();
          break;
        case 2:
          this.setGuestTypeChosen();
          break;
        case 3:
          this.deleteChosen();
          break;
      }
    },
    setGuestTypeChosen() {
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          if (this.chosen.guests.includes(g)) {
            g.guest_type = g.guest_type === 3 ? 1 : 3;
            g.selected = true;
          }
        });
      });
    },
    saveDates(date) {
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          if (this.chosen.guests.filter((chosen) => chosen.guest_id === g.guest_id)[0]) {
            g.selected = true;
            g.spaces.forEach((s) => {
              if (s.start_date) s.end_date = date;
            });
          }
          g.cars.map((c) => {
            if (this.chosen.cars.filter((chosen) => chosen.car_id === c.car_id)[0]) {
              c.end_date = date;
              c.status = 2;
              g.selected = true;
              if (c.one_time) {
                c.start_date = date;
              }
            }
          });
        });
      });
    },
    changeContentHandler() {
      for (let depth of Object.keys(this.chosen)) {
        for (let item of this.chosen[depth]) {
          item.chosen = true;
        }
      }
      let newChosen = { ...this.chosen };
      this.$emit("changeSelected", newChosen);
    },
    deleteChosenGroups({ forced }) {
      // if (this.chosen.subgroups.length === 0) return;
      let groups = this.chosen.subgroups.map((i) => i.group_id);
      if (this.chosenAll) groups.push(this.group.group_id);
      groups.map((group) => {
        if (group) this.$store.dispatch("guests/deleteGroup", { group, notify: false });
      });

      let data = {
        groups,
        guest_ids: [],
        forced,
      };
      base({
        url: "/guests",
        method: "DELETE",
        data,
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code === 107) {
            let cars = response.data.body.map((i) => i.car_plate);
            this.$confirm({
              message: this.$t("guest_del_prompt_parked", {
                cars,
              }),
              button: {
                no: this.$t("cancel"),
                yes: this.$t("delete"),
              },
              callback: (confirm) => {
                if (confirm) {
                  this.deleteChosenGroups({ forced: true });
                }
              },
            });
          } else {
            this.$store.commit("guests/reset_status", true);
            this.redirectToResult();
          }
        }
      });
    },

    deleteChosenGuests({ forced }) {
      this.$emit("setSending");
      let data = {
        guest_ids: this.chosen.guests.map((i) => i.guest_id),
        forced,
      };
      base({
        url: "/guests",
        method: "DELETE",
        data,
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code === 107) {
            let cars = response.data.body.map((i) => i.car_plate);
            this.$confirm({
              message: this.$t("guest_del_prompt_parked", {
                cars,
              }),
              button: {
                no: this.$t("cancel"),
                yes: this.$t("delete"),
              },
              callback: (confirm) => {
                if (confirm) {
                  this.deleteChosenGuests({ forced: true });
                }
              },
            });
          } else {
            this.$store.commit("guests/reset_status", true);
            this.redirectToResult();
          }
        }
      });
    },

    deleteChosenCars({ forced }) {
      this.$emit("setSending");
      let data = {
        guest_vehicles: this.chosen.cars.map((i) => i.guest_car_id),
        forced,
      };
      base({
        url: "/guests/vehicles",
        method: "DELETE",
        data,
      }).then((response) => {
        if (response.status == 200) {
          if (response.data.code === 107) {
            let cars = response.data.body.map((i) => i.car_plate);
            this.$confirm({
              message: this.$t("guest_del_prompt_parked", {
                cars,
              }),
              button: {
                no: this.$t("cancel"),
                yes: this.$t("delete"),
              },
              callback: (confirm) => {
                if (confirm) {
                  this.deleteChosenCars({ forced: true });
                }
              },
            });
          } else {
            this.$store.commit("guests/reset_status", true);
            this.redirectToResult();
          }
        }
      });
    },

    deleteChosen() {
      this.$confirm({
        message: this.$t("form_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.chosen.guests.length) this.deleteChosenGuests({ forced: false });
            if (this.chosen.cars.length) this.deleteChosenCars({ forced: false });
            if (this.chosen.subgroups.length) this.deleteChosenGroups({ forced: false });
          }
        },
      });
    },
    reformatCars(cars) {
      return cars
        ? cars.map((car) => {
            return {
              ...car,
              start_date: new Date(car.start_date).toISOString(),
              end_date: new Date(car.end_date).toISOString(),
            };
          })
        : [];
    },
  },
};
</script>
