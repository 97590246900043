import { base, cyberport } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs'
// import Vue from 'vue'
import i18n from '@/plugins/i18n';
import EventBus from '../../../event-bus';

export default {
    getKioskCheckoutCalculation({ commit }, params) {
        return base({
            url: '/payments/calculation',
            params,
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat'
                })
            },
            method: 'GET'
        })
          .then((response) => {
              commit('set_kiosk_checkout_amount', response.data.body.price / 100);
              commit('set_kiosk_checkout_amount_loaded_status', true);
          })
          .catch((err) => {
              console.log(err.response)
          });
    },
    getZoneHistory({ commit }, { params, status }) {
        let place_id = params.place_id && params.place_id.length ?
            params.place_id : store.state.app.places
            .map(p => p.place_id)

        base({
                url: '/zonal/zone_history',
                params: {
                    ...params,
                    place_id,
                    status
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
                method: 'GET'
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || []
                    if (status === 1) {
                        commit('get_zone_history_complied', history);
                    } else if (status === 2) {
                        commit('get_zone_history_violated', history);
                    } else {
                        commit('get_zone_history_success', history);
                    }
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
    },
    getHistory({
        commit,
        dispatch
    }, params) {
        let place_id = params.place_id && params.place_id.length > 0 ?
            params.place_id : store.state.app.places
            .map(p => p.place_id)
        params.guest_type = params.guest_type || store.getters['app/guestTypes']
        let features = []
        if (store.getters['app/hasZones']) features.push('zonal')
        if (store.getters['app/hasOverParking']) features.push('overparking')
        params = {
            ...params,
            features,
            place_id,
            status: 0,
        }
        if (!params.incrementing) commit("set_loader", true)
        if (!params.init && !params.search) {
            dispatch("getCurrentRecords", {...params, status: 1})
            dispatch("getCompletedRecords", {...params, status: 2})
            dispatch("getUnpaidRecords", {...params })
        }
        if (params.search && params.tab) {
            switch (params.tab) {
                case 1:
                    dispatch("getCurrentRecords", {...params, status: 1})
                    break
                case 2:
                    dispatch("getCompletedRecords", {...params, status: 2})
                    break
                case 3:
                    dispatch("getUnpaidRecords", {...params })
                    break
                default:
                    break
            }
            return
        }
        base({
                url: '/history',
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
                method: 'GET'
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || []
                        // console.log(history)
                    commit('get_history_success', history);
                    dispatch("app/updateBalanceRequest", null, {
                        root: true
                    })
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
    },
    getCompletedRecords({ commit }, params) {
        let url = '/history'
        if (store.getters['app/hasOverParking']) url += '/completed'
        base({
                url,
                params: {
                    ...params,
                    status: 2
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
                method: 'GET'
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || [];
                    // console.log(history)
                    commit('get_completed_records_success', history);
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
    },
    getCurrentRecords({ commit }, params) {
        let url = '/history'
        if (!params.incrementing) commit("set_loader", true)
        if (store.getters['app/hasOverParking']) url += '/current'
        let limit = 0
        store.state.app.places.map(place => {
            limit = limit + place.place_count
        })
        base({
                url,
                params: {
                    status: 1,
                    ...params,
                    limit,
                    from: params.to
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
                method: 'GET'
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || [];
                    // console.log(history)
                    commit('get_current_records_success', history);
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
    },
    getUnpaidRecords({ commit }, params) {
        let url = '/history'
        base({
            url,
            params: {
                ...params,
                payment_status: "unpaid",
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat'
                })
            },
            method: 'GET'
        })
          .then((response) => {
              if (response.status === 200) {
                  let history = response.data.body || [];
                  // console.log(history)
                  commit('get_unpaid_records_success', history);
              }
          })
          .catch((err) => {
              console.log(err.response)
          });
    },
    getHistoryNumbers({
        commit,
        dispatch
    }) {
        let place_id = store.state.app.places.map(p => p.place_id)
        base({
                url: '/history/last_numbers',
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let payload = response.data.body || []
                    commit('set_last_numbers', payload);
                }
            })
            .catch((err) => {
                if (err.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },

    getRecordCalculation({ commit }, params) {
        commit("set_loader_calculation", true)
        base({
                url: '/history/calculation',
                method: 'GET',
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let payload = response.data.body || {}
                    payload.blocks = payload.blocks || []
                    commit("set_current_calculation", payload)
                    commit("set_loader_calculation", false)
                }
            })
            .catch((err) => {
                console.log(err.response)
            });
    },

    addRecord({ commit }, { data, type }) {
        base({
                url: "/history_edits",
                method: "POST",
                data,
            }).then(res => {
                if (res.status === 200) {
                    commit("edit_record", { value: true, type })
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    updateRecord({ commit }, data) {
        base({
                url: "/history_edits",
                method: "PUT",
                data,
            }).then(res => {
                if (res.status === 200) {
                    commit("edit_record", { value: true, type: 2 })
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    deleteRecord({ commit }, { params, notify }) {
        base({
                url: "/history_edits",
                method: "DELETE",
                params
            }).then(res => {
                if (res.status === 200) {
                    commit("edit_record", { value: true, type: 3, notify })
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },

    setPayment({ commit }, { data, notify }) {
        base({
                url: "/payments/non-pbnb",
                method: "POST",
                data,
            }).then(res => {
                if (res.status === 200) {
                    console.log("set paid success")
                    if (notify) {
                        commit("refresh_status")
                        EventBus.$emit("notify", {
                            group: 'general',
                            title: 'Success!',
                            text: i18n.t('parking_paid'),
                            duration: 3000
                        })
                    }

                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    setRecordPaid({ commit }, data) {
        cyberport({
                url: "/platform/pay",
                method: "POST",
                data,
            }).then(res => {
                if (res.status === 200) {
                    commit("edit_record", { value: true, type: 2 })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
    cancelPayment({ commit }, ) {
        base({
                url: "/customer-service/payment/cancel",
                method: "POST",
            }).then(res => {
                if (res.status === 200) {
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },

    getReport({ commit }, {
        params,
        route
    }) {
        commit("app/set_downloader", true, {
            root: true
        })
        let place_id = store.state.app.places.map(p => p.place_id)
        let lang = localStorage.getItem("language") || "en-us"
        lang = lang.replace("-", "_")
        let name;
        switch (route) {
            case '/guests/report':
                name = '_guests_'
                break
            case '/account/report':
                name = '_payment_'
                break;
            case '/history/report':
                name = '_history_'
                break
            case '/promo/report':
                name = '_promo-history_'
                break
            case '/zonal/zone_history/report':
                name = '_zone-history_'
                break
            case '/logs':
            case '/events/report':
                name = '_logs_'
                break
            case '/history/overtime_report':
                name = '_overparking_'
                break
            case '/history/usage_report':
                name = '_usage_'
                break

        }
        let dates = params.from.slice(0, 10) + '/' + params.to.slice(0, 10)
        base({
                url: route,
                params: {
                    ...params,
                    lang,
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                if (response.status === 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'report' + name + dates + '.xlsx');
                    // fileLink.setAttribute('download', 'report' + name + dates + '.json');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    commit("app/set_downloader", false, {
                        root: true
                    })
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },
}
